import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import Api from '../../../configs/Api';

const CertificateDetailPage = () => {
  let { id } = useParams();

  const [certificate, setCertificate] = useState();

  useEffect(() => {
    Api.get(`/certificates/${id}`)
      .then((response) => {
        setCertificate(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  return (
    <>
      <Header />
      <div className='min-h-screen' style={{ backgroundColor: '#f1f1f1' }}>
        <div className='container mb-4 flex'>
          {certificate && (
            <div className='w-full flex flex-col justify-center items-center p-4'>
              <img src={certificate.imageURL} alt="cert" />

              <a
                className='bg-blue-600 text-white px-4 mt-4 py-3 text-md font-semibold rounded'
                href={certificate.imageURL}
                download
              >
                Download a certificate
              </a>
            </div>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
}
export default CertificateDetailPage;
