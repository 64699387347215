import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => {
  return(
    <footer>
      <Container>
        <Row style={{ paddingTop: 40, paddingBottom: 40 }}>
          <Col></Col>
          <Col className="text-center">
            <p style={{ fontWeight: 'bold',  color: 'gray' }}>Made with ❤️ from REBEL Team</p>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </footer>
  );
};
export default Footer;
