import { useNavigate } from 'react-router-dom';
import { FiHome, FiLayers, FiLogOut, FiAward, FiSmile, FiSettings } from 'react-icons/fi';

import { useAdmin } from '../../../hooks/useAdmin';
import LogoImage from './images/rebel-logo.png';

const Layout = ({ children }) => {
  const navigate = useNavigate();
  const { username } = useAdmin();

  return (
    <div className="min-h-screen w-screen flex">
      <div className="fixed w-96 h-full bg-white flex flex-col justify-between items-center shadow-lg p-8">
        <div className='flex flex-col justify-center items-center'>
          <img src={LogoImage} alt="" width={80} height={80} onClick={() => navigate('/')} className="cursor-pointer" />
          <h2 className='pt-4 text-gray-600 text-xl font-semibold mb-1'>Admin Panel</h2>
          <p className='text-sm text-gray-600 mb-24'>{username}</p>

          <div className='flex items-center cursor-pointer mb-4' onClick={() => navigate('/admin')}>
            <FiHome size={20} color={`#525252`}  />
            <p className='ml-2 font-semibold text-gray-600'>Dashboard</p>
          </div>
          <div className='flex items-center cursor-pointer mb-4' onClick={() => navigate('/admin/cards')}>
            <FiLayers size={20} color={`#525252`}  />
            <p className='ml-2 font-semibold text-gray-600'>Cards</p>
          </div>
          <div className='flex items-center cursor-pointer mb-4' onClick={() => navigate('/admin/certificates')}>
            <FiAward size={20} color={`#525252`}  />
            <p className='ml-2 font-semibold text-gray-600'>Certificates</p>
          </div>
          <div className='flex items-center cursor-pointer mb-20' onClick={() => navigate('/admin/organizations')}>
            <FiSmile size={20} color={`#525252`}  />
            <p className='ml-2 font-semibold text-gray-600'>Organizations</p>
          </div>
          <div className='flex items-center cursor-pointer mb-4'>
            <FiSettings size={20} color={`#525252`}  />
            <p className='ml-2 font-semibold text-gray-600'>Settings</p>
          </div>
          <div className='flex items-center cursor-pointer mb-4' onClick={() => navigate('/admin/signout')}>
            <FiLogOut size={20} color={`#525252`}  />
            <p className='ml-2 font-semibold text-gray-600'>Sign out</p>
          </div>
        </div>

        <div className='text-gray-400 font-semibold'>REBEL Team</div>
      </div>

      <div className='w-full flex bg-slate-100 pl-96'>
        {children}
      </div>
    </div>
  );
}
export default Layout;
