import { useNavigate } from 'react-router-dom';

import Header from "../components/Header";
import Footer from "../components/Footer";
import Onboarding3 from "../images/onboarding3.gif";

const OnboardingTwo= () => {
  const navigate = useNavigate();
  return (
    <>
      <Header />

      <div style={{ backgroundColor: '#f1f1f1', minHeight: 'calc(100vh - 160px)' }}>
        <div className='container flex pt-4'>
          <div className='w-2/3 p-12 px-20'>
            <img src={Onboarding3} alt="1" />
          </div>

          <div className='w-1/3 flex flex-col justify-center'>
            <div className='bg-gray-500 rounded font-bold text-2xl text-white flex justify-center items-center w-12 h-12 mb-12'>3</div>
            <h1 className={`font-bold text-5xl mb-3`}>SAVE '&' SHARE</h1>
            <p className={`text-gray-500 mb-12`}>Once you have prioritised your final se of REBEL capabilities and competencies, add a description of the activity to find transcript and save it as personal record of learning gain.</p>
            <button
              className='bg-black text-2xl w-52 text-white px-8 py-3 font-semibold rounded'
              onClick={() => navigate('/app')}
            >
              Get started
            </button>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
export default OnboardingTwo;
