import { withAdminAuthen } from '../../../hocs/withAdminAuthen';
import AdminLayout from '../../../components/admin/Layout';

const AdminHomePage = () => {
  return (
    <AdminLayout>
      <div className='w-1/2 p-8'>
        <h1 className='font-bold text-2xl mb-4'>Admin Dashboard</h1>
      </div>
    </AdminLayout>
  );
}
export default withAdminAuthen(AdminHomePage);
