import { useNavigate } from 'react-router-dom';

import Header from "../components/Header";
import Footer from "../components/Footer";
import Onboarding2 from "../images/onboarding2.gif";

const OnboardingTwo= () => {
  const navigate = useNavigate();
  return (
    <>
      <Header />

      <div style={{ backgroundColor: '#f1f1f1', minHeight: 'calc(100vh - 160px)' }}>
        <div className='container flex pt-4'>
          <div className='w-2/3 p-12 px-20'>
            <img src={Onboarding2} alt="1" />
          </div>

          <div className='w-1/3 flex flex-col justify-center'>
            <div className='bg-gray-500 rounded font-bold text-2xl text-white flex justify-center items-center w-12 h-12 mb-12'>2</div>
            <h1 className={`font-bold text-5xl mb-3`}>Reflect</h1>
            <p className={`text-gray-500 mb-12`}>From this selection, it is now possible to reflect in more detail about an activity or project that you have undertake or might be planning. Decide on which of the learning capabilities have the most relevance or significance to you.</p>
            <button
              className='bg-black text-2xl w-52 text-white px-8 py-3 font-semibold rounded'
              onClick={() => navigate('/onboardingthree')}
            >
              Next
            </button>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
export default OnboardingTwo;
