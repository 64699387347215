import { useEffect, useState } from "react";

import Api from '../../../configs/Api';
import { withAdminAuthen } from "../../../hocs/withAdminAuthen"
import AdminLayout from '../../../components/admin/Layout';


const AdminCertificateListPage = () => {
  const [certificates, setCertificates] = useState([]);
  const [selectedCertificate, setSelectedCertificate] = useState();

  useEffect(() => {
    Api.get('/certificates')
      .then(response => {
        setCertificates(response.data);
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  return (
    <AdminLayout>
      <div className='w-1/2 p-8'>
        <h1 className='font-bold text-2xl mb-4'>Certificates ({certificates.length})</h1>

        {certificates.map((certificate, index) => (
          <div
            key={`certificate-${index}`}
            className='bg-white p-4 rounded mb-2 cursor-pointer flex flex-row justify-between'
            onClick={() => setSelectedCertificate(certificate)}
          >
            <div className='font-semibold'>{certificate.fullName}</div>
            <div className='font-semibold'>{new Date(certificate.createdAt).toLocaleString()}</div>
          </div>
        ))}
      </div>

      <div className='w-1/2 p-8'>
        {selectedCertificate && (
          <img className='fixed w-1/3' src={selectedCertificate.imageURL} alt='certificate' />
        )}
      </div>
    </AdminLayout>
  );
}
export default withAdminAuthen(AdminCertificateListPage);
