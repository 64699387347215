import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { GoLightBulb } from 'react-icons/go';
import { IoHandRightOutline } from 'react-icons/io5';
import { IoMdHeartEmpty } from 'react-icons/io';

import Footer from '../components/Footer';
import Header from '../components/Header';
import Api from '../configs/Api';

const HomePage = () => {
  const navigate = useNavigate();

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    Api.get('/categories')
      .then(response => {
        setCategories(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <Header />

      <div style={{ backgroundColor: '#f1f1f1', paddingBottom: 120 }}>
        <Container style={{ paddingTop: 80 }}>
          <Row style={{ marginBottom: 40 }}>
            <Col>
              <h1 className="text-center font-bold text-6xl mb-4">Reflect, Evalute, Plan</h1>
              <h4 className="text-center text-gray-500 text-lg"> </h4>
            </Col>
          </Row>

          <Row style={{ paddingTop: 20, marginBottom: 60 }} className="text-center">
            <Col></Col>
            <Col>
              <button
                className='bg-blue-600 text-white px-8 py-4 text-xl font-semibold rounded mb-4'
                onClick={() => navigate('/card/explore')}
              >Explore REBEL</button>
              <div className='text-gray-600'>
                Browse through the attitube <br />against colours
              </div>
            </Col>
            <Col>
              <button
                className='bg-gray-400 text-white px-8 py-4 text-xl font-semibold rounded mb-4'
                onClick={() => navigate('/backpacks')}
              >Load a backpack</button>
              <div className='text-gray-600'>Load a preset backpack such as UNESCO<br /> or your preset backpack</div>
            </Col>
            <Col></Col>
          </Row>

          <div className='w-full flex flex-row justify-center'>
            <div className='flex flex-col mr-4'>
              <div className='bg-white flex justify-center p-4 mb-3 font-semibold text-gray-600'>Ways of Being</div>
              <div className='text-center flex justify-center'>
                {categories.filter(category => category.group === 'Ways of Being').map((category, index) => (
                  <div
                    key={`categories-${category.id}`}
                    className={`bg-white last:mr-0 mr-4 w-40 cursor-pointer drop-shadow-xl hover:shadow-xl hover:opacity-100 transition duration-150`}
                  >
                    <div
                      className='h-28 text-bold text-white flex justify-center items-center'
                      style={{ backgroundColor: category.color }}
                    >
                      <IoMdHeartEmpty className='w-8 h-8 opacity-60' />
                    </div>
                    <div className='p-3 text-gray-800 font-semibold'>{category.name}</div>
                  </div>
                ))}
              </div>
            </div>

            <div className='flex flex-col mr-4'>
              <div className='bg-white flex justify-center p-4 mb-3 font-semibold text-gray-600'>Ways of Thinking</div>
              <div className='text-center flex justify-center'>
                {categories.filter(category => category.group === 'Ways of Thinking').map((category, index) => (
                  <div
                    key={`categories-${category.id}`}
                    className={`bg-white last:mr-0 mr-4 w-40 cursor-pointer drop-shadow-xl hover:shadow-xl hover:opacity-100 transition duration-150`}
                  >
                    <div
                      className='h-28 text-bold text-white flex justify-center items-center'
                      style={{ backgroundColor: category.color }}
                    >
                      <GoLightBulb className='w-8 h-8 opacity-60' />
                    </div>
                    <div className='p-3 text-gray-800 font-semibold'>{category.name}</div>
                  </div>
                ))}
              </div>
            </div>

            <div className='flex flex-col'>
              <div className='bg-white flex justify-center p-4 mb-3 font-semibold text-gray-600'>Ways of Doing</div>
              <div className='text-center flex justify-center'>
                {categories.filter(category => category.group === 'Ways of Doing').map((category, index) => (
                  <div
                    key={`categories-${category.id}`}
                    className={`bg-white last:mr-0 mr-4 w-40 cursor-pointer drop-shadow-xl hover:shadow-xl hover:opacity-100 transition duration-150`}
                  >
                    <div
                      className='h-28 text-bold text-white flex justify-center items-center'
                      style={{ backgroundColor: category.color }}
                    >
                      <IoHandRightOutline className='w-8 h-8 opacity-60' />
                    </div>
                    <div className='p-3 text-gray-800 font-semibold'>{category.name}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>

        </Container>
      </div>

      <Footer />
    </>
  );
}
export default HomePage;
