import { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';

import AdminCardListPage from './pages/admin/Cards';
import AdminCertificateListPage from './pages/admin/Certificates';
import AdminHomePage from './pages/admin/Home';
import AdminSignInPage from './pages/admin/SignIn';
import AdminSignInCallbackPage from './pages/admin/SignInCallback';
import AdminSignOutPage from './pages/admin/SignOut';
import AdminOrganizationListPage from './pages/admin/Organizations';
import AdminAddOrganizationPage from './pages/admin/AddOrganization';

import BoardPage from './pages/Board';
import AboutPage from './pages/About';
import AppPage from './pages/App';
import TipsPage from './pages/Tips';
import CardSortPage from './pages/cards/Sort';
import ContactPage from './pages/Contact';
import ExploreCardPage from './pages/cards/Explore';
import BackpackListPage from './pages/backpacks/List';
import BackpackDetailPage from './pages/backpacks/Detail';
import LandingPage from './pages/Landing';
import HomePage from './pages/Home';
import OnboardingOne from './pages/OnboardingOne';
import OnboardingTwo from './pages/OnboardingTwo';
import OnboardingThree from './pages/OnboardingThree';
import SummaryPage from './pages/cards/Summary';
import CertificationPage from './pages/Certification';
import CertificateDetailPage from './pages/certificates/Detail';

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path='/admin/cards' element={<AdminCardListPage />} exact />
        <Route path='/admin/certificates' element={<AdminCertificateListPage />} exact />
        <Route path='/admin/organizations' element={<AdminOrganizationListPage />} exact />
        <Route path='/admin/organizations/new' element={<AdminAddOrganizationPage />} exact />
        <Route path='/admin/signin/callback' element={<AdminSignInCallbackPage />} exact />
        <Route path='/admin/signin' element={<AdminSignInPage />} exact />
        <Route path='/admin/signout' element={<AdminSignOutPage />} exact />
        <Route path='/admin' element={<AdminHomePage />} exact />

        <Route path='/certificate' element={<CertificationPage />} exact />
        <Route path='/certificates/:id' element={<CertificateDetailPage />} exact />
        <Route path='/card/explore' element={<ExploreCardPage />} exact />
        <Route path='/card/summary' element={<SummaryPage />} exact />
        <Route path='/card/sort' element={<CardSortPage />} exact />
        <Route path='/backpacks/:id' element={<BackpackDetailPage />} exact />
        <Route path='/backpacks' element={<BackpackListPage />} exact />
        <Route path='/board/:id' element={<BoardPage />} exact />
        <Route path='/landing' element={<LandingPage />} exact />
        <Route path='/onboardingone' element={<OnboardingOne />} exact />
        <Route path='/onboardingtwo' element={<OnboardingTwo />} exact />
        <Route path='/onboardingthree' element={<OnboardingThree />} exact />
        <Route path='/about' element={<AboutPage />} />
        <Route path='/contact' element={<ContactPage />} />
        <Route path='/tips' element={<TipsPage />} />
        <Route path='/app' element={<AppPage />} />
        <Route path='/' element={<HomePage />} exact />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
