import { FiMail } from 'react-icons/fi';

import { API_HOST } from '../../../configs/Api';
import LogoImage from './images/rebel-logo.png';

const AdminSignInPage = () => {
  const onClickSignInWithMicrosoft = () => {
    window.location.href = `${API_HOST}/auth/microsoft`;
  }

  return(
    <div className="container h-screen flex flex-col justify-center items-center pb-20">
      <img src={LogoImage} alt="" width={80} />
      <h3 className='font-bold text-xl mt-4 mb-8'>Welcome to REBEL Admin panel!</h3>

      <button
        onClick={onClickSignInWithMicrosoft}
        className='bg-black text-white px-4 py-3 font-semibold rounded mr-4 flex'
      >
        <FiMail size={24} className='mr-4' />
        Sign in with Microsoft
      </button>
    </div>
  );
}
export default AdminSignInPage;
