import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { CardWithSmallSize } from '../../components/cards/Card';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Api from '../../configs/Api';

import LogoImage from './images/rebel-logo.png';

const CertificationPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [cards, setCards] = useState([]);
  const [organizations, setOrganization] = useState([]);
  const [name, setName] = useState('');
  const [activity, setActivity] = useState('');
  const [code, setCode] = useState('');

  const datetime = new Date().toLocaleString();

  useEffect(() => {
    Api.get('/organizations').then(res => setOrganization(res.data));
  }, []);

  useEffect(() => {
    setCards(location.state.cards);
  }, [location]);

  const onClickDownloadCertificate = () => {
    Api.post(
      '/certificates',
      {
        fullName: name,
        description: activity,
        organizationCode: code,
        cards
      },
    )
    .then(response => {
      navigate(`/certificates/${response.data.id}`);
    })
    .catch(error => {
      console.log(error);
    });
  }

  const getCertificateLogo = () => {
    if (organizations.find(organization => organization.code === code)) {
      return organizations.filter(org => org.code === code)[0].logoUrl;
    }
    return LogoImage;
  }

  return (
    <>
      <Header />
      <div className='min-h-screen' style={{ backgroundColor: '#f1f1f1' }}>
        <div className='container mb-4 flex'>
          <div className='w-1/2'>
            <div className='bg-white p-4 m-8 shadow-lg flex flex-col items-center'>
              <img src={getCertificateLogo()} alt="" width={120} />
              <div className='font-bold text-2xl my-4'>
                Learning Record
              </div>

              <div className='bg-gray-100 w-5/6 h-0.5 mb-4'></div>

              <div className='w-2/3 flex flex-col justify-center text-center mb-8'>
                <div className='font-semibold text-gray-600 text-2xl mb-1'>
                  {name || 'Your name'}
                </div>
                <div className='text-gray-600'>
                  {activity || 'Your activity'}
                </div>
                <div>

                </div>
              </div>

              <div className='flex flex-wrap justify-center mb-4'>
                {cards.map((card, index) => (
                  <CardWithSmallSize
                    key={`card-${index}`}
                    card={card}
                  />
                ))}
              </div>

              <div className='font-semibold text-gray-400 mb-1'>
                Certified by: rebel-tool.org
              </div>
              <div className='text-sm text-gray-400'>
                {datetime}
              </div>
            </div>
          </div>
          <div className='w-1/2 flex flex-col pl-12'>
            <div className='pt-12'>
              <h1 className='text-4xl font-semibold mb-2'>Certificate</h1>
              <h4 className='text-gray-600 mb-8'>In order to download certificate, please complete all information</h4>
            </div>

            <label className='font-semibold mb-2'>Your full name</label>
            <input className='border-2 border-black p-2 mb-4' placeholder='Your name' onChange={e => setName(e.target.value)} />

            <label className='font-semibold mb-2'>Activity description</label>
            <textarea className='border-2 border-black p-2 mb-4' placeholder='Describe your activity while using REBEL' rows={5} onChange={e => setActivity(e.target.value)} />

            <label className='font-semibold mb-2'>Organization code (Optional)</label>
            <input className='border-2 border-black p-2 mb-8' placeholder='Code from staff' onChange={e => setCode(e.target.value)} />

            <button
              className='bg-blue-600 text-white px-4 py-3 text-md font-semibold rounded w-60'
              onClick={onClickDownloadCertificate}
            >
              Get a certificate
            </button>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
export default CertificationPage;
