import { useState, useEffect } from "react";

export const useAdmin = () => {
  const [username, setUsername] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token');
    const email = JSON.parse(atob(token.split('.')[1])).user.email;
    setUsername(email);
  }, []);

  return { username };
}
