import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import Header from "../components/Header";

const LandingPage = () => {
    const navigate = useNavigate();

  return (
    <>
      <Header />

      <div style={{ backgroundColor: '#f1f1f1', minHeight: 'calc(100vh - 80px)' }}>
        <Container style={{ padding: 20 }}>
          <Row>
            <Col>
              <h1>Landing</h1>
              <div className='text-center pt-12 flex justify-center mb-12'>
              <div
                className={`bg-white mr-4 w-40 cursor-pointer drop-shadow-xl hover:shadow-xl hover:opacity-100 transition duration-150`}
              >
              <div className='h-28' style={{ backgroundColor: '#000'}}></div>
              <div className='p-3 text-gray-800 font-semibold'> Sift & Sort </div>
              <div className='p-3 text-gray-800 font-semibold text-xs'> See more </div>
            </div>

           {/* {space} */}

              <div className={`bg-white mr-4 w-40 cursor-pointer drop-shadow-xl hover:shadow-xl hover:opacity-100 transition duration-150`}
              >
              <div className='h-28' style={{ backgroundColor: '#000'}}></div>
              <div className='p-3 text-gray-800 font-semibold'> Select & Reflect </div>
              <div className='p-3 text-gray-800 font-semibold text-xs'> See more </div>
            </div>

          {/* {space} */}
              <div
                className={`bg-white mr-4 w-40 cursor-pointer drop-shadow-xl hover:shadow-xl hover:opacity-100 transition duration-150`}
              >
              <div className='h-28' style={{ backgroundColor: '#000'}}></div>
              <div className='p-3 text-gray-800 font-semibold'> Save & Share </div>
              <div className='p-3 text-gray-800 font-semibold text-xs'> See more </div>
            </div>
            </div>
            </Col>
          </Row>
          <Row>
              <div className='container text-center'>
          <button className="w-40 content-center bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
          onClick={() => navigate('/onboardingone')}
          >
                        Ready!
          </button>
          </div>
          </Row>
        </Container>
      </div>
    </>
  );
}
export default LandingPage;
