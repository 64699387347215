import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FiPlus } from 'react-icons/fi';

import { withAdminAuthen } from "../../../hocs/withAdminAuthen"
import AdminLayout from '../../../components/admin/Layout';
import Api from '../../../configs/Api';

const AdminOrganizationListPage = () => {
  const navigate = useNavigate();

  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    Api.get('/organizations').then(res => setOrganizations(res.data));
  }, []);

  return (
    <AdminLayout>
      <div className='w-full p-8'>
        <h1 className='font-bold text-2xl mb-4'>Organizations ({organizations.length})</h1>

        <div className="flex flex-wrap">
          {organizations.map((organization, index) => (
            <div className="w-60 h-60 rounded-lg bg-white drop-shadow-xl hover:shadow-xl hover:opacity-100 transition duration-150 cursor-pointer mr-4 mb-3 flex flex-col justify-center items-center" key={`organization-${index}`}>
              <img className="w-2/3 mb-4" src={organization.logoUrl} alt={organization.name} />
              <h3 className='text-lg font-bold'>{organization.name}</h3>
              <p className='text-gray-500'>Code: {organization.code}</p>
            </div>
          ))}
          <div
            className="w-60 h-60 rounded-lg bg-gray-100 drop-shadow-xl hover:shadow-xl hover:opacity-100 transition duration-150 cursor-pointer mr-4 mb-3 flex flex-col justify-center items-center"
            key={`organization-new`}
            onClick={() => navigate('/admin/organizations/new')}
          >
            <FiPlus size={40} color={`#909090`}  />
            <h3 className='font-bold text-gray-500 mt-2'>Add organization</h3>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}
export default withAdminAuthen(AdminOrganizationListPage);
