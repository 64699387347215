import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { CardWithoutSelectStage }from '../../../components/cards/Card';
import Header from '../../../components/Header';
import Api from '../../../configs/Api';

// import LogoImage from './images/rebel-logo.png';

const CardSummaryPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedCards, setSelectedCard] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setSelectedCard(location.state.cards);
  }, [location]);

  const onClickExportToMiro = () => {
    setIsLoading(true);
    Api.post(
      '/cards/export-to-miro',
      { 'cards': selectedCards },
    )
      .then(response => {
        const { miroBoardId } = response.data;
        setTimeout(() => {
          navigate(`/board/${miroBoardId}`);
        }, 2000)
      })
      .catch(error => console.log(error));
  }

  return (
    <>
      <Header />

      <div className='min-h-screen' style={{ backgroundColor: '#f1f1f1' }}>
        <div className='container mb-4'>
          <div className='text-center pt-12'>
            <h1 className='text-4xl font-semibold mb-2'>Summary</h1>
            <h4 className='text-gray-600'>You have selected {selectedCards.length} cards</h4>
          </div>
        </div>

        <div className='container flex justify-center mb-8'>
          <div className='w-2/3 flex flex-wrap justify-center'>
            {selectedCards.map((card, index) => (
              <CardWithoutSelectStage key={`card-${index}`} card={card} />
            ))}
          </div>
        </div>

        <div className='text-center'>
          <button
            className='bg-gray-400 text-white px-8 py-4 text-xl font-semibold rounded mr-4'
            onClick={() => navigate('/certificate', {state: { cards: selectedCards }})}
          >
            Download certificate
          </button>

          <button
            className='bg-blue-600 text-white px-8 py-4 text-xl font-semibold rounded'
            onClick={onClickExportToMiro}
          >
            {isLoading ? ('Processing...') : ('Reflect')}
          </button>
        </div>
      </div>
    </>
  );
}
export default CardSummaryPage;
