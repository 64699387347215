import { useEffect, useState } from 'react';

import Api from '../../../configs/Api';
import AdminLayout from '../../../components/admin/Layout';
import { withAdminAuthen } from '../../../hocs/withAdminAuthen';

const AdminCardListPage = () => {
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(undefined);

  useEffect(() => {
    Api.get('/cards')
      .then(response => {
        const cardsWithState= response.data.map(card => ({...card, isSelected: false}))
        setCards(cardsWithState);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const onCompetencyChange = (event) => {
    setSelectedCard({...selectedCard, competency: event.target.value});
  }
  const onDescriptionChange = (event) => {
    setSelectedCard({...selectedCard, description: event.target.value});
  }
  const onSave = () => {
    Api.put(`/cards/${selectedCard.id}`, selectedCard)
      .then(response => {
        const updatedCards = cards.map(card => {
          if (card.id === selectedCard.id) {
            return {...selectedCard, isSelected: false};
          }
          return card;
        });
        setCards(updatedCards);
        setSelectedCard(undefined);
      })
      .catch(error => {
        console.log(error);
      });
  }

  return (
    <AdminLayout>
      <div className='w-1/2 p-8'>
        <h1 className='font-bold text-2xl mb-4'>Cards ({cards.length})</h1>

        <div className='overflow-auto' style={{ height: 'calc(100vh - 120px)'}}>
          {cards.map((card, index) => (
            <div
              key={`card-${index}`}
              className='bg-white p-4 rounded mb-2 cursor-pointer flex flex-row justify-between'
              onClick={() => setSelectedCard(card)}
            >
              <div className='font-semibold'>{card.competency}</div>
              <div className='p-1 px-3 rounded' style={{ backgroundColor: card.color }}>
                <p className='text-sm font-semibold'>{card.category}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='w-1/2 p-8'>
        {selectedCard && (
          <div className='bg-white rounded h-full p-8 flex flex-col'>
            <div className=''>
              <h1 className='text-4xl font-semibold mb-2 mb-4'>Edit a card</h1>
              {/* <h4 className='text-gray-600 mb-8'>In order to download certificate, please complete all information</h4> */}
            </div>

            <label className='font-semibold mb-2'>Competency</label>
            <input className='border-2 border-black p-2 mb-4 rounded' value={selectedCard.competency} onChange={onCompetencyChange} />

            <label className='font-semibold mb-2'>Description</label>
            <textarea className='border-2 border-black p-2 mb-8 rounded' value={selectedCard.description} onChange={onDescriptionChange} rows={3} />

            <div className='flex items-center justify-center'>
              <button
                className='bg-blue-600 text-white px-4 py-3 text-md font-semibold rounded w-40'
                onClick={onSave}
              >
                Save
              </button>
            </div>
          </div>
        )}
      </div>
    </AdminLayout>
  );
}
export default withAdminAuthen(AdminCardListPage);
