import { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player';

import Header from "../../components/Header";
import Footer from '../../components/Footer';

const HomePage = () => {
  const navigate = useNavigate();
  const [isUnderMaintainance, setIsUnderMaintainance] = useState(true);

  const user = localStorage.getItem('token');

  useEffect(() => {
    if (user) setIsUnderMaintainance(false);
  }, [user]);

  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);
  const div = useCallback(node => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);



  return (
    <>
      <Header />

      <div className='min-h-screen' style={{ backgroundColor: '#f1f1f1' }}>
        {isUnderMaintainance && (
          <div className='container flex flex-col justify-center items-center h-80'>
            <h1 className='text-6xl'>🎁</h1>
            <h1 className='font-bold text-xl text-gray-700 mb-1 mt-4'>We've got something special for you!</h1>
            <p className='text-gray-500'>And we can't wait for you to see it. Please check back soon.</p>
          </div>
        )}

        {!isUnderMaintainance && (
          <div className='container flex pt-20'>
            <div className='w-1/3 justify-center flex flex-col'>
              <h1 className={`font-bold text-6xl mb-2`}> REBEL</h1>
              <p className={`font-semibold text-4xl text-gray-500 mb-8`}> Recognition of Experience Based Learning</p>

              <div className=''>
                <button
                    className='bg-black text-white px-4 py-3 font-semibold rounded mr-4'
                    onClick={() => navigate('/app')}
                  >
                  Get started
                </button>
                <button
                  className="w-40 content-center text-black font-semibold py-3 px-4 border-black border-2 rounded"
                  onClick={() => navigate('/onboardingone')}
                >
                  Tutorial
                </button>
              </div>
            </div>

            <div className='w-2/3' style={{ height: '500px' }} ref={div}>
              <ReactPlayer
                url='https://player.vimeo.com/video/632802686?h=676cdd06bc'
                className='w-full'
                width={width}
                height={height}
                controls
              />
            </div>
          </div>
        )}
      </div>

      <Footer />
    </>
  );
}
export default HomePage;
