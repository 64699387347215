import { Navigate, useLocation } from "react-router-dom";

const AdminSignInCallbackPage = () => {
  const { search } = useLocation();
  const token = new URLSearchParams(search).get('token');

  localStorage.setItem('token', token);

  return (<Navigate to='/admin' />);
}
export default AdminSignInCallbackPage;
