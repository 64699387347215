import { useState, useEffect, useRef, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react'
import { FiXCircle } from 'react-icons/fi';

import Header from '../../components/Header';
import CategoryCard from '../../components/cards/CategoryCard';
import { Card } from '../../components/cards/Card';
import SubCategoryCard from '../../components/cards/SubCategoryCard';
import Api from '../../configs/Api';
import MiniCard from '../../components/cards/MiniCard';

const ExploreCardPage = () => {
  const MAX_CARDS_IN_BACKPACK = 18;
  const navigate = useNavigate();
  const closeCardModalRef = useRef(null);

  const [categories, setCategories] = useState([]);
  const [cards, setCards] = useState([]);
  const [isShowCards, setIsShowCards] = useState(false);
  const [isDisplayCardTooltip, setIsDisplayCardTooltip] = useState(false);

  useEffect(() => {
    Api.get('/categories')
      .then(response => {
        const categoriesWithState = response.data
          .map(category => ({...category, isSelected: false}))
          .map(category => {
            category.subCategories = category.subCategories.map(subCategory => ({...subCategory, isSelected: false, isHovering: false }));
            return category;
          });
        setCategories(categoriesWithState);
      })
      .catch(error => {
        console.log(error);
      });
    Api.get('/cards')
      .then(response => {
        const cardsWithState= response.data.map(card => ({...card, isSelected: false}))
        setCards(cardsWithState);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const getSelectedCategory = () => {
    return categories.filter(category => category.isSelected)[0];
  }
  const setSelectedCategory = (id) => {
    const updatedCategories = categories.map(category => {
      if (category.id === id) category.isSelected = true;
      else category.isSelected = false;

      category.subCategories = category.subCategories.map(subCategory => ({...subCategory, isSelected: false}));
      return category;
    });
    setCategories(updatedCategories);
  }

  const getSelectedSubCategory = () => {
    return categories
      .filter(category => category.isSelected)[0].subCategories
      .filter(subCategory => subCategory.isSelected)[0];
  }
  const setSelectedSubCategory = (subCategoryName) => {
    const updatedSubCategories = categories.map(category => {
      if (category.id === getSelectedCategory().id) category.isSelected = true;
      else category.isSelected = false;

      category.subCategories = category.subCategories.map(subCategory => {
        if (subCategory.name === subCategoryName) subCategory.isSelected = true;
        else subCategory.isSelected = false;
        return subCategory;
      });
      return category
    });
    setCategories(updatedSubCategories);
    setIsShowCards(true);
  }
  const setHoveringSubCategory = (subCategoryName) => {
    const updatedSubCategories = categories.map(category => {
      category.subCategories = category.subCategories.map(subCategory => {
        if (subCategory.name === subCategoryName) subCategory.isHovering = true;
        else subCategory.isHovering = false;
        return subCategory;
      });
      return category
    });
    setCategories(updatedSubCategories);

    setIsDisplayCardTooltip(value => !value);
  }

  const onClickCard = (clickedCard) => {
    if (clickedCard.isSelected) {
      const updatedCards = cards.map(card => {
        if (card.id === clickedCard.id) card.isSelected = !card.isSelected;
        return card;
      });
      setCards(updatedCards);
    } else {
      const currentCardInBackPack = cards.filter(card => card.isSelected).length;
      if (currentCardInBackPack < MAX_CARDS_IN_BACKPACK) {
        const updatedCards = cards.map(card => {
          if (card.id === clickedCard.id) card.isSelected = !card.isSelected;
          return card;
        });
        setCards(updatedCards);
      }
    }
  }

  return (
    <div className='min-h-screen' style={{ backgroundColor: '#f1f1f1' }}>
      {/* TODO: Animate tooltip effect */}
      {/* <Transition
        show={isDisplayCardTooltip}
        enter="transform transition ease-out duration-500"
        enterFrom="opacity-0 translate-y-full"
        enterTo="opacity-100 translate-y-0"
        leave="transform transition ease-out duration-500"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-full"
      >
        <div className="fixed top-0 left-0 w-screen h-screen bg-black opacity-80 z-10"></div>
      </Transition> */}
      {isDisplayCardTooltip && (
        <div className="fixed top-0 left-0 w-screen h-screen bg-black opacity-80 z-10"></div>
      )}


      <Header />
{/*
      <button
          className="transition-opacity duration-1000 ease-out opacity-100 hover:opacity-0 bg-pink-500 text-white font-bold py-2 px-4 rounded-lg">
          Vanish Slowly 💨
        </button>

        <div class="transition-opacity duration-700 opacity-50 hover:opacity-100 p-2 px-8 rounded-lg bg-green-500 text-green-50 w-52">Hover me</div>
<br />
<p>Uses the <code>transition-opacity</code> class with <code>bg-opacity-[n[</code>.</p>
<div class="transition-colors duration-700 bg-opacity-50 hover:bg-opacity-100 p-2 px-8 rounded-lg bg-red-500 text-red-50 w-52">Hover me</div>

<button class="text-white bg-green-500
            md:border-2 rounded-lg p-5
            hover:shadow-inner w-56 text-2xl
            transform hover:scale-125
            hover:bg-green-700 transition
            ease-out duration-500">
        Hover
    </button> */}

      <div className=''>
        <div className='container'>
          {/* <div className='text-center pt-12'>
            <h1 className='text-4xl font-semibold'>Explore REBEL</h1>
          </div> */}

          <div className='text-center pt-12 flex justify-center mb-8'>
            {categories.map(category => (
              <CategoryCard
                key={`categories-${category.id}`}
                category={category}
                setSelectedCategory={setSelectedCategory}
                selectedCategory={getSelectedCategory()}
                cards={cards}
              />
            ))}
          </div>

          <div className='flex justify-center'>
            <div className='flex flex-wrap justify-center mb-12'>
              {getSelectedCategory() && getSelectedCategory().subCategories && getSelectedCategory().subCategories.map((subCategory, index) => (
                <SubCategoryCard
                  key={`sub-categories-${index}`}
                  subCategory={subCategory}
                  setSelectedSubCategory={setSelectedSubCategory}
                  selectedCategory={getSelectedCategory()}
                  cards={cards.filter(card => card.subCategory === subCategory.name)}
                  setHoveringSubCategory={setHoveringSubCategory}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className='fixed w-full bottom-0 h-24 bg-white flex flex-row justify-between'>
        <div className='flex flex-col justify-center items-center w-40 text-gray-600'>
          <div><strong className='text-4xl text-black'>{cards.filter(card => card.isSelected).length}</strong> /18</div>
          <div>Backpack</div>
        </div>
        <div className='w-full flex items-center overflow-x-auto'>
          {cards.filter(card => card.isSelected).map(card => (
            <MiniCard
              key={`card-in-backpack-${card.id}`}
              card={card}
              onClickCard={onClickCard}
            />
          ))}
        </div>
        <div className='w-40 flex justify-center items-center'>
          <h4 className='font-bold cursor-pointer' onClick={() => navigate('/card/sort', {state: { cards: cards.filter(card => card.isSelected) }})}>Next</h4>
        </div>
      </div>

      <Transition appear show={isShowCards} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-30 overflow-y-auto"
          onClose={() => setIsShowCards(false)}
          initialFocus={closeCardModalRef}
        >
          <div className="min-h-screen px-4 text-center flex items-center justify-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-80 transition-opacity" />
            </Transition.Child>

            <div className='grid grid-cols-3'>
              {cards && getSelectedCategory() && getSelectedSubCategory() && cards.filter(card => card.category === getSelectedCategory().name && card.subCategory === getSelectedSubCategory().name).map(card => (
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                  key={`cards-${card.id}`}
                >
                  <div className='inline-block w-full max-w-md overflow-hidden align-middle'>
                    <Card
                      card={card}
                      onClickCard={onClickCard}
                    />
                  </div>
                </Transition.Child>
              ))}
            </div>

            <div className='absolute top-10 right-10 cursor-pointer' onClick={() => setIsShowCards(false)} ref={closeCardModalRef}>
              <FiXCircle size={40} color={`#ffffff`}  />
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
export default ExploreCardPage;
