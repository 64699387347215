import { useParams } from "react-router-dom";

import Header from "../components/Header";

const BoardPage = () => {
  const { id } = useParams();

  return (
    <>
      <Header />

      <div>
        <iframe style={{ width: '100%', minHeight: 'calc(100vh - 80px)' }} title="miro-board-for-rebel" src={`https://miro.com/app/live-embed/${id}/?embedAutoplay=true`} frameBorder="0" scrolling="no" allowFullScreen></iframe>
      </div>
    </>
  );
}
export default BoardPage;
