import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import FormData from 'form-data'

import { withAdminAuthen } from "../../../hocs/withAdminAuthen"
import AdminLayout from '../../../components/admin/Layout';
import Api from '../../../configs/Api';

const AdminAddOrganizationPage = () => {
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [selectedFile, setSelectedFile] = useState();

  const onClickSubmit = () => {
    console.log(name, code, selectedFile);

    const formData = new FormData();
    formData.append('name', name);
    formData.append('code', code);
    formData.append("logo", selectedFile);

    Api.post('/organizations', formData, { headers: { "Content-Type": "multipart/form-data" }})
      .then(res => navigate('/admin/organizations'))
      .catch(error => {
        console.log(error)
      });
  };

  return (
    <AdminLayout>
      <div className='w-full p-8'>
        <h1 className='font-bold text-2xl mb-4'>Add new organization</h1>

        <div className="flex flex-wrap">
          <div className='bg-white rounded h-full p-8 flex flex-col'>
            <label className='font-semibold mb-2'>Organization Name</label>
            <input className='border-2 border-black p-2 mb-4 rounded' value={name} onChange={(e) => setName(e.target.value)} />
            <label className='font-semibold mb-2'>Organization Code</label>
            <input className='border-2 border-black p-2 mb-4 rounded' value={code} onChange={(e) => setCode(e.target.value)} />

            <label className='font-semibold mb-2'>Organization Logo</label>
            <input
              className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              type="file"
              name="organization"
              onChange={(e) => setSelectedFile(e.target.files[0])}
            ></input>

            <div className='flex items-center justify-center'>
              <button
                className='bg-blue-600 text-white px-4 py-3 mt-12 text-md font-semibold rounded w-40'
                onClick={onClickSubmit}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}
export default withAdminAuthen(AdminAddOrganizationPage);
