import Header from '../../components/Header';
import Footer from '../../components/Footer';

import Sunflowers from './images/sunflowers.jpg';

const BackpackListPage = () => {
  return (
    <div className='min-h-screen' style={{ backgroundColor: '#f1f1f1' }}>
      <Header />

      <div className='container flex justify-center my-20'>
        <div className='w-2/3 text-center'>
          <h1 className='text-6xl font-semibold mb-4'>👀 Explore - 💭 Reflect</h1>
          <h4 className='text-gray-600'>
            From here in, the aim is to get down to 6 cards that reflect or capture something of your experience. Let's go back to your original question and review each card. Right column if its 100% yes, left column if you're not as certain.
          </h4>
        </div>
      </div>

      <div className='container mb-4'>
        <h2 className='font-bold text-xl'>UNESCO Backpacks</h2>
      </div>
      <div className='container grid grid-flow-row-dense grid-cols-3 grid-rows-2 gap-3 mb-20'>
        <div className={`col-span-2 bg-white last:mr-0 cursor-pointer drop-shadow-lg hover:shadow-xl hover:opacity-100 transition duration-150`}>
          <div className='text-bold text-white'>
            <img src={Sunflowers} alt="flower" className="object-cover h-48 w-full"  />
          </div>
          <div className='p-3'>
            <h3 className='text-gray-800 text-lg font-semibold'>Reflect your life</h3>
            <p className='text-gray-400'>IOREM is a network of scholars in the area of remittances and migration and focuses on research.</p>
          </div>
        </div>

        <div className={`col-span-1 bg-white last:mr-0 cursor-pointer drop-shadow-lg hover:shadow-xl hover:opacity-100 transition duration-150`}>
          <div className='text-bold text-white'>
            <img src={Sunflowers} alt="flower" className="object-cover h-48 w-full"  />
          </div>
          <div className='p-3'>
            <h3 className='text-gray-800 text-lg font-semibold'>Reflect your life</h3>
            <p className='text-gray-400'>IOREM is a network of scholars in the area of remittances and migration and focuses on research.</p>
          </div>
        </div>

        <div className={`col-span-1 bg-white last:mr-0 cursor-pointer drop-shadow-lg hover:shadow-xl hover:opacity-100 transition duration-150`}>
          <div className='text-bold text-white'>
            <img src={Sunflowers} alt="flower" className="object-cover h-48 w-full"  />
          </div>
          <div className='p-3'>
            <h3 className='text-gray-800 text-lg font-semibold'>Reflect your life</h3>
            <p className='text-gray-400'>IOREM is a network of scholars in the area of remittances and migration and focuses on research.</p>
          </div>
        </div>

        <div className={`col-span-1 bg-white last:mr-0 cursor-pointer drop-shadow-lg hover:shadow-xl hover:opacity-100 transition duration-150`}>
          <div className='text-bold text-white'>
            <img src={Sunflowers} alt="flower" className="object-cover h-48 w-full"  />
          </div>
          <div className='p-3'>
            <h3 className='text-gray-800 text-lg font-semibold'>Reflect your life</h3>
            <p className='text-gray-400'>IOREM is a network of scholars in the area of remittances and migration and focuses on research.</p>
          </div>
        </div>

        <div className={`col-span-1 bg-white last:mr-0 cursor-pointer drop-shadow-lg hover:shadow-xl hover:opacity-100 transition duration-150`}>
          <div className='text-bold text-white'>
            <img src={Sunflowers} alt="flower" className="object-cover h-48 w-full"  />
          </div>
          <div className='p-3'>
            <h3 className='text-gray-800 text-lg font-semibold'>Reflect your life</h3>
            <p className='text-gray-400'>IOREM is a network of scholars in the area of remittances and migration and focuses on research.</p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
export default BackpackListPage;
