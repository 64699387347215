import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { RiCloseCircleLine, RiCloseLine, RiCheckLine } from 'react-icons/ri';
import { TiTickOutline } from 'react-icons/ti';

import Header from "../../components/Header";
import { LIGHT_COLOR_CATEGORIES } from '../../configs/Card';

function Groups() {
  const location = useLocation();
  const navigate = useNavigate();

  const [items, setItems] = useState(location.state.cards);
  const [itemsWithNo, setItemsWithNo] = useState([]);
  const [itemsWithYes, setItemsWithYes] = useState([]);

  const onClickNo = item => {
    if (itemsWithNo.length < 12){
      setItemsWithNo([item, ...itemsWithNo]);
      setItemsWithYes(itemsWithYes.filter(card => card.id !== item.id));
      setItems(items.filter(card => card.id !== item.id));
    }
  }

  const onClickYes = item => {
    if (itemsWithYes.length < 6) {
      setItemsWithYes([item, ...itemsWithYes]);
      setItemsWithNo(itemsWithNo.filter(card => card.id !== item.id));
      setItems(items.filter(card => card.id !== item.id));
    }
  }

  const renderCard = (item, index) => {
    const isTextBlack = LIGHT_COLOR_CATEGORIES.includes(item.category)

    return (
      <div
        key={`cards-${item.id}`}
        className={`w-64 h-56 ${isTextBlack ? 'text-black' : 'text-white'} drop-shadow-xl p-4 flex flex-col flex-wrap justify-between items-center absolute inset-0`}
        style={{ backgroundColor: item.color, zIndex: -index }}
      >
        <div>
          <h3 className='font-semibold text-lg mb-2'>{item.competency}</h3>
          <h3 className=''>{item.description}</h3>
        </div>
      </div>
    );
  };
  const renderMiniCard = (item) => {
    const isTextBlack = LIGHT_COLOR_CATEGORIES.includes(item.category)

    return (
      <div
        key={`yes-cards-${item.id}`}
        className={`w-32 h-28 ${isTextBlack ? 'text-black' : 'text-white'} drop-shadow-xl p-1 justify-between m-2 cursor-pointer text-xs group`}
        style={{ backgroundColor: item.color }}
        onClick={() => onClickNo(item)}
      >
        <span className='hidden group-hover:block m-auto z-10 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 justify-center'><RiCloseCircleLine size={32}  style={{ fill: 'black' }}/></span>
        <div className={``}>
          <h3 className='font-semibold text-xs mb-2'>{item.competency}</h3>
          <h3 className=''>{item.description}</h3>
        </div>
      </div>
    )
  };

  return (
    <>
      <Header />

      <div className='min-h-screen' style={{ backgroundColor: '#f1f1f1' }}>
        {/* <div className='container flex justify-center mb-12'>
          <div className='w-1/3 text-center pt-12'>
            <h1 className='text-4xl font-semibold mb-4'>Refine</h1>
            <h4 className='text-gray-600'>
            From here in, the aim is to get down to 6 cards that reflect or capture something of your experience. Let's go back to your original question and review each card. Right column if its 100% yes, left column if you're not as certain.   </h4>
          </div>
        </div> */}

        <div className='container flex' style={{ height: '600px' }}>
          <div className="flex-1 bg-gray-300 p-8 flex flex-col items-center">
            <div className='bg-red-300 rounded-full w-16 h-16 flex justify-center items-center mb-2'><RiCloseLine size={40} color={`#b70000`} /></div>
            <h3 className='font-bold text-xl'>Discard</h3>
            <p>{itemsWithNo.length}/12</p>
            <div className='grid grid-cols-3 gap-4'>
              {itemsWithNo.map(item => (
                <div
                  key={`no-cards-${item.id}`}
                  className={`w-32 h-28 text-white drop-shadow-xl p-2 flex flex-col flex-wrap justify-between m-4 cursor-pointer text-xs group`}
                  style={{ backgroundColor: item.color }}
                  onClick={() => onClickYes(item)}
                >
                  <span className='hidden group-hover:block m-auto z-10 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 justify-center'> <TiTickOutline size={32}  style={{ fill: 'black' }}/></span>
                  <div>
                    <h3 className='font-semibold text-xs mb-2 '>{item.competency}</h3>
                    <h3 className=''>{item.description}</h3>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className='flex-1 flex flex-col justify-center items-center'>
            <div className="h-60 w-60 relative mb-12">
              {items.map((item, index) => (renderCard(item, index)))}
            </div>

            {items.length !== 0 && (
              <div className='flex'>
                <div
                  className='bg-red-300 rounded-full w-16 h-16 flex justify-center items-center mb-2 mr-4 cursor-pointer'
                  onClick={() => onClickNo(items[0])}
                >
                  <RiCloseLine size={40} color={`#b70000`} />
                </div>
                <div
                  className='bg-green-300 rounded-full w-16 h-16 flex justify-center items-center mb-2 cursor-pointer'
                  onClick={() => onClickYes(items[0])}
                >
                  <RiCheckLine size={40} color={`#0a8926`} />
                </div>
              </div>
            )}
          </div>

          <div className="flex-1 bg-gray-300 p-8 flex flex-col items-center">
            <div className='bg-green-300 rounded-full w-16 h-16 flex justify-center items-center mb-2'><RiCheckLine size={40} color={`#0a8926`} /></div>
            <h3 className='font-bold text-xl'>Keep</h3>
            <p>{itemsWithYes.length}/6</p>
            <div className='grid grid-cols-3 gap-4'>
              {itemsWithYes.map(item => renderMiniCard(item))}
            </div>
          </div>
        </div>

        {items.length === 0 && (
          <div className='sticky bottom-20 flex justify-end pr-8'>
            <div className='w-32 bg-white text-center border-2 border-black p-2 cursor-pointer'>
              <h3 className='font-bold text-2xl' onClick={() => navigate('/card/summary', {state: { cards: itemsWithYes }})}>Confirm</h3>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
export default Groups;
