// import { useNavigate } from 'react-router-dom';
import { Container, Nav, Row, Col } from 'react-bootstrap';

const Header = () => {
  // const navigate = useNavigate();

  return(
    <header style={{ backgroundColor: '#ffffff' }}>
      <Container>
        <Row style={{ paddingTop: 20, paddingBottom: 20, height: '80px' }}>
          <Col>
            <a href="/" style={{ textDecoration: 'none' }}>
              <h2 style={{ fontWeight: 'bold',  color: 'black', fontSize: 32 }}>REBEL</h2>
            </a>
          </Col>
          <Col>
            <Nav className="justify-content-center">
              <Nav.Item>
                <Nav.Link href="/">
                  <div style={{ color: 'black', fontWeight: 'bold' }} >Home</div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="#">
                  <div style={{ color: 'gray', fontWeight: 'bold' }} >About</div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="#">
                  <div style={{ color: 'gray', fontWeight: 'bold' }} >Tips</div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="#">
                  <div style={{ color: 'gray', fontWeight: 'bold' }} >Contact</div>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col className='text-right'>
            <button
                className='bg-black text-white px-4 py-2 font-semibold rounded'
                // onClick={() => navigate('/app')}
              >
                Explore REBEL
            </button>
          </Col>
        </Row>
      </Container>
    </header>
  );
};
export default Header;
