import { Navigate } from "react-router-dom";

export const withAdminAuthen = (Component) => {
  return (props) => {
    const user = localStorage.getItem('token');

    // TODO: JWT decode and validate access
    if (user) {
      return <Component {...props} />;
    }
    return <Navigate to='/admin/signin' />;
  }
}
